import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Bezier } from 'bezier-js';
import {
  drawDoubleEndedArrow,
  drawLine,
  drawTriangle,
  writeText,
} from '../../../utils/canvasUtils';
import styles from './SCurveSlider.module.css';

import Man from '../../../assets/Man.svg';
import Woman from '../../../assets/Woman.svg';

export default function SCurveSlider({
  value,
  setValue,
  isInteractive,
  className,
}: {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  isInteractive?: boolean;
  className: string;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [manImage, setManImage] = useState<HTMLImageElement | null>(null);
  const [womanImage, setWomanImage] = useState<HTMLImageElement | null>(null);

  const padding = 0.2 * height;
  const leftCurve = {
    startPoint: { x: 0 + padding, y: height - padding },
    controlPoint: { x: 0.5 * width, y: height - padding },
    endPoint: { x: 0.5 * width, y: 0.5 * height },
  };
  const rightCurve = {
    startPoint: { x: 0.5 * width, y: 0.5 * height },
    controlPoint: { x: 0.5 * width, y: padding },
    endPoint: { x: width - padding, y: padding },
  };

  const getValueFromPosition = useCallback(
    (x: number) => ((x - padding) / (width - 2 * padding)) * 100,
    [width, height],
  );

  const getPositionFromValue = useCallback(
    (val: number) => (val / 100) * (width - 2 * padding) + padding,
    [width, height],
  );

  const getYFromX = useCallback(
    (x: number) => {
      if (x < width / 2) {
        const leftBezier = new Bezier(
          leftCurve.startPoint,
          leftCurve.controlPoint,
          leftCurve.endPoint,
        );
        const leftT = leftBezier.intersects({
          p1: { x, y: 0 },
          p2: { x, y: height },
        });
        const leftPoint = leftBezier.get(Number(leftT[0]));
        return leftPoint.y;
      }
      const rightBezier = new Bezier(
        rightCurve.startPoint,
        rightCurve.controlPoint,
        rightCurve.endPoint,
      );
      const rightT = rightBezier.intersects({
        p1: { x, y: 0 },
        p2: { x, y: height },
      });
      const rightPoint = rightBezier.get(Number(rightT[0]));
      return rightPoint.y;
    },
    [width, height],
  );

  const getMousePos = useCallback(
    (event: { clientX: number; clientY: number }) => {
      const canvas = canvasRef?.current?.getBoundingClientRect();
      if (canvas == null) {
        return { x: 0, y: 0 };
      }
      return {
        x: event.clientX - canvas.left,
        y: event.clientY - canvas.top,
      };
    },
    [canvasRef],
  );

  const drawBackground = useCallback(
    (context: CanvasRenderingContext2D) => {
      // === RAIL ===
      context.beginPath();
      context.moveTo(leftCurve.startPoint.x, leftCurve.startPoint.y);
      context.quadraticCurveTo(
        leftCurve.controlPoint.x,
        leftCurve.controlPoint.y,
        leftCurve.endPoint.x,
        leftCurve.endPoint.y,
      );
      context.quadraticCurveTo(
        rightCurve.controlPoint.x,
        rightCurve.controlPoint.y,
        rightCurve.endPoint.x,
        rightCurve.endPoint.y,
      );
      context.strokeStyle = '#ff5b5b';
      context.setLineDash([0]);
      context.lineWidth = 5;
      context.stroke();

      // === DOTTED LINES ===
      drawLine(
        context,
        { x: padding, y: height - padding },
        { x: padding, y: height },
        'black',
        true,
      );

      const secondX = 0.166 * (width - 2 * padding) * 2 + padding;
      drawLine(
        context,
        { x: secondX, y: height },
        { x: secondX, y: getYFromX(secondX) },
        'black',
        true,
      );

      const thirdX = 0.166 * (width - 2 * padding) * 4 + padding;
      drawLine(
        context,
        { x: thirdX, y: height },
        { x: thirdX, y: getYFromX(thirdX) },
        'black',
        true,
      );

      drawLine(
        context,
        { x: width - padding, y: height },
        { x: width - padding, y: getYFromX(width - padding) },
        'black',
        true,
      );

      const wordBottomPadding = -20;
      // === GROUND ZERO ===
      writeText(
        context,
        'Ground Zero',
        '16px sans-serif',
        'black',
        {
          x: leftCurve.startPoint.x - 40,
          y: leftCurve.startPoint.y - 40 + wordBottomPadding,
        },
        0,
      );

      // === GAINING COMPETENCE ===
      writeText(
        context,
        'Gaining Competence',
        '16px sans-serif',
        'black',
        {
          x: 0.166 * (width - 2 * padding) + padding - 75,
          y: height + wordBottomPadding,
        },
        0,
      );

      // === HYPERGROWTH ==
      writeText(
        context,
        'Hyper Growth',
        '16px sans-serif',
        'black',
        {
          x: 0.166 * (width - 2 * padding) * 3 + padding - 50,
          y: height + wordBottomPadding,
        },
        0,
      );

      // === EXPERTISE ==
      writeText(
        context,
        'Expertise',
        '16px sans-serif',
        'black',
        {
          x: 0.166 * (width - 2 * padding) * 5 + padding - 25,
          y: height + wordBottomPadding,
        },
        0,
      );

      // === MASTERY ===
      writeText(
        context,
        'Mastery',
        '16px sans-serif',
        'black',
        {
          x: rightCurve.endPoint.x - 25,
          y: rightCurve.endPoint.y - 40 + wordBottomPadding,
        },
        0,
      );

      // === ARROWS ===
      drawTriangle(
        context,
        {
          x: leftCurve.startPoint.x,
          y: leftCurve.startPoint.y - 10,
        },
        {
          x: leftCurve.startPoint.x - 20,
          y: leftCurve.startPoint.y - 30,
        },
        {
          x: leftCurve.startPoint.x + 20,
          y: leftCurve.startPoint.y - 30,
        },
        '#ff5b5b',
      );

      drawTriangle(
        context,
        {
          x: rightCurve.endPoint.x,
          y: rightCurve.endPoint.y - 10,
        },
        {
          x: rightCurve.endPoint.x - 20,
          y: rightCurve.endPoint.y - 30,
        },
        {
          x: rightCurve.endPoint.x + 20,
          y: rightCurve.endPoint.y - 30,
        },
        '#ff5b5b',
      );

      // === RANGE ARROWS ===

      const arrowPadding = 10;
      drawDoubleEndedArrow(
        context,
        20,
        { x: padding + arrowPadding, y: height - arrowPadding },
        { x: secondX - arrowPadding, y: height - arrowPadding },
        '#ff5b5b',
      );

      drawDoubleEndedArrow(
        context,
        20,
        { x: secondX + arrowPadding, y: height - arrowPadding },
        { x: thirdX - arrowPadding, y: height - arrowPadding },
        '#ff5b5b',
      );

      drawDoubleEndedArrow(
        context,
        20,
        { x: thirdX + arrowPadding, y: height - arrowPadding },
        { x: width - padding - arrowPadding, y: height - arrowPadding },
        '#ff5b5b',
      );

      // === IMAGES ===
      if (manImage) {
        context.drawImage(manImage, secondX, getYFromX(secondX) - 110, 70, 110);
      }
      if (womanImage) {
        context.drawImage(womanImage, thirdX, getYFromX(thirdX) - 110, 70, 110);
      }
    },
    [manImage, womanImage, height, width],
  );

  const drawSliderKnob = useCallback(
    (context: CanvasRenderingContext2D, x: number) => {
      const y = getYFromX(x);
      context.fillStyle = '#283972';
      context.beginPath();
      context.arc(x, y, 15, 0, 2 * Math.PI);
      context.fill();
    },
    [getYFromX],
  );

  const drawSCurve = useCallback(
    (x: number) => {
      const canvas = canvasRef.current;
      if (canvas == null) {
        return;
      }
      const context = canvas.getContext('2d');
      if (context == null) {
        return;
      }
      if (x < leftCurve.startPoint.x || x > rightCurve.endPoint.x) return;
      context.clearRect(0, 0, canvas.width, canvas.height); // clear the canvas
      drawBackground(context);
      drawSliderKnob(context, x);
    },
    [canvasRef, drawBackground, drawSliderKnob, leftCurve, rightCurve],
  );

  // update the value with slider is interacted with
  const sliderMouseEventHandler = useCallback(
    (event: { clientX: number; clientY: number }) => {
      if (!isDragging || !isInteractive) {
        return;
      }
      const { x } = getMousePos(event);
      if (x < padding || x > width - padding) {
        return;
      }
      const newValue = getValueFromPosition(x);
      setValue(newValue);
      drawSCurve(x);
    },
    [
      isDragging,
      isInteractive,
      setValue,
      drawSCurve,
      getMousePos,
      getValueFromPosition,
    ],
  );

  // on component mount
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) {
      return;
    }
    drawSCurve(getPositionFromValue(value));
  }, [canvasRef, drawSCurve]);

  // update size of canvas to match that of css
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) {
      return;
    }
    const widthClient = canvas.clientWidth;
    const heightClient = canvas.clientHeight;

    if (canvas.width !== widthClient || canvas.height !== heightClient) {
      canvas.width = widthClient;
      canvas.height = heightClient;
      setWidth(widthClient);
      setHeight(heightClient);
    }
  }, [canvasRef]);

  // load images
  useEffect(() => {
    if (!setManImage || !setWomanImage) return;
    const man = new Image();
    man.onload = () => {
      setManImage(man);
    };
    man.src = Man;

    const woman = new Image();
    woman.onload = () => {
      setWomanImage(woman);
    };
    woman.src = Woman;
  }, [setManImage, setWomanImage]);

  return (
    <canvas
      ref={canvasRef}
      className={className}
      onMouseMove={sliderMouseEventHandler}
      onMouseDown={(event) => {
        if (!isInteractive) return;
        setIsDragging(true);
        const { x } = getMousePos(event);
        drawSCurve(x);
      }}
      onMouseUp={(event) => {
        if (!isInteractive) return;
        setIsDragging(false);
        const { x } = getMousePos(event);
        drawSCurve(x);
      }}
      onMouseLeave={() => {
        if (!isInteractive) return;
        setIsDragging(false);
      }}
    />
  );
}

SCurveSlider.defaultProps = {
  isInteractive: true,
};

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import SCurveSlider from '../SCurveSlider';
import NavigationButton from '../../../shared/NavigationButton';

import styles from './SCurveDescription.module.css';

const SCurveDescription = ({
  handleNext,
  handleBack,
}: {
  handleNext: () => void;
  handleBack: () => void;
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(30);

  const getTitleAndDescription = () => {
    if (value < 1) {
      return {
        title: (
          <h4>
            <b>{t('activity.sCurve.slide2.groundZero.title')}</b>
          </h4>
        ),
        description: (
          <h4>{t('activity.sCurve.slide2.groundZero.description')}</h4>
        ),
      };
    }
    if (value >= 1 && value <= 33) {
      return {
        title: (
          <h4>
            <b>{t('activity.sCurve.slide2.gaining.title')}</b>
          </h4>
        ),
        description: <h4>{t('activity.sCurve.slide2.gaining.description')}</h4>,
      };
    }
    if (value > 33 && value <= 66) {
      return {
        title: (
          <h4>
            <b>{t('activity.sCurve.slide2.hypergrowth.title')}</b>
          </h4>
        ),
        description: (
          <h4>{t('activity.sCurve.slide2.hypergrowth.description')}</h4>
        ),
      };
    }
    if (value > 66 && value < 99) {
      return {
        title: (
          <h4>
            <b>{t('activity.sCurve.slide2.high.title')}</b>
          </h4>
        ),
        description: <h4>{t('activity.sCurve.slide2.high.description')}</h4>,
      };
    }
    if (value >= 99) {
      return {
        title: (
          <h4>
            <b>{t('activity.sCurve.slide2.mastery.title')}</b>
          </h4>
        ),
        description: <h4>{t('activity.sCurve.slide2.mastery.description')}</h4>,
      };
    }
  };
  const { title, description } = getTitleAndDescription();

  return (
    <div>
      <Grid container spacing={3} className="flex justify-center px-12 xl:px-0">
        <Grid item lg={12} className="flex justify-center px-12 xl:px-0">
          <div className={styles.container}>
            <Grid className="h-1/2 text-2xl text-center">
              <h2>{t('activity.sCurve.slide2.title')}</h2>
              <h4>{t('activity.sCurve.slide2.description')}</h4>
              <>
                {title}
                {description}
              </>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} className="flex justify-center px-12 xl:px-0">
          <SCurveSlider
            value={value}
            setValue={setValue}
            className={styles.slider}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="flex justify-between">
            <NavigationButton onClick={handleBack} />
            <NavigationButton onClick={handleNext} isNext alignRight />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SCurveDescription;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import styles from './SCurveIntro.module.css';
import NavigationButton from '../../../shared/NavigationButton';

const SCurveIntro = ({
  setSlide,
}: {
  setSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const previews = useStaticQuery(graphql`
    query {
      scurve: file(relativePath: { eq: "activities/scurve.png" }) {
        childImageSharp {
          fluid(maxHeight: 150) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const handleNext = () => {
    setSlide(2);
  };

  return (
    <Grid container className={styles.container}>
      <Grid item lg={8}>
        <div>
          <h2>{t('activity.sCurve.title')}</h2>
          <h4>{t('activity.sCurve.header1')}</h4>
          <h4>{t('activity.sCurve.header2')}</h4>
        </div>
      </Grid>
      <Grid item lg={8}>
        <GatsbyImage
          loading="eager"
          className="h-1/2 w-1/2 mx-auto"
          fluid={previews.scurve.childImageSharp.fluid}
        />
      </Grid>
      <NavigationButton isNext onClick={handleNext} alignRight />
    </Grid>
  );
};

export default SCurveIntro;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { MdLightbulbOutline } from 'react-icons/md';
import Alert from '@material-ui/lab/Alert';
import { Grid } from '@material-ui/core';

import useSCurve from '../../../../hooks/useSCurve';
import SCurveSlider from '../SCurveSlider';
import ModalContext from '../../../../contexts/ModalContext';

import ROOT_URL from '../../../../constants/url';
import NavigationButton from '../../../shared/NavigationButton';
import Button from '../../../shared/Button';

import styles from './SCurveReflection.module.css';
import Reflections from '../../Reflections';

const SCurveReflection = ({
  setSlide,
}: {
  setSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const { updateSCurveReflection, submitSCurve, sCurve } = useSCurve();
  const { emitter, events } = useContext(ModalContext);

  const [loading, setLoading] = useState(true);
  const [reflection, setReflection] = useState(sCurve?.reflection ?? '');
  const [value, setValue] = useState(sCurve?.stage ?? 0);
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loading === true) {
      (async () => {
        if (!sCurve) {
          navigate('app/journal/activity/sCurve');
          toast.error(t('activity.sCurve.toasts.doesNotExist'));
          return null;
        }

        setLoading(false);
      })();
    }
  }, [loading, sCurve]);

  useEffect(() => {
    if (loading === true) {
      return;
    }
    updateSCurveReflection(reflection);
  }, [reflection]);

  const handleClick = () => {
    submitSCurve();
    const link = getSharableUrl();
    const message = t(`modals.share.scurveMessage`);
    emitter.emit(events.SCURVE_MODAL, { node: pageRef.current, link, message });
  };

  const handleBack = () => {
    setSlide(3);
  };

  const getSharableUrl = () => {
    const sCurveId = sCurve?.id;
    return `${ROOT_URL}/app/journal/activity/scurve/${sCurveId}`;
  };

  const getReflectionPrompts = () => {
    if (value === 0) {
      return {
        prompts: (
          <>
            <p>{t('activity.sCurve.slide4.reflection.groundzero.q1')}</p>
            <p>{t('activity.sCurve.slide4.reflection.groundzero.q2')}</p>
          </>
        ),
      };
    }

    if (value > 0 && value <= 33) {
      return {
        prompts: (
          <>
            <p>{t('activity.sCurve.slide4.reflection.gaining.q1')}</p>
            <p>{t('activity.sCurve.slide4.reflection.gaining.q2')}</p>
            <p>{t('activity.sCurve.slide4.reflection.gaining.q3')}</p>
            <p>{t('activity.sCurve.slide4.reflection.gaining.note1')}</p>
            <p>{t('activity.sCurve.slide4.reflection.gaining.note2')}</p>
            <p>{t('activity.sCurve.slide4.reflection.gaining.note3')}</p>
            <p>{t('activity.sCurve.slide4.reflection.gaining.note4')}</p>
            <p>{t('activity.sCurve.slide4.reflection.gaining.note5')}</p>
          </>
        ),
      };
    }

    if (value > 33 && value <= 66) {
      return {
        prompts: (
          <>
            <p>{t('activity.sCurve.slide4.reflection.hypergrowth.q1')}</p>
            <p>{t('activity.sCurve.slide4.reflection.hypergrowth.q2')}</p>
          </>
        ),
      };
    }

    if (value > 66 && value < 100) {
      return {
        prompts: (
          <>
            <p>{t('activity.sCurve.slide4.reflection.high.q1')}</p>
            <p>{t('activity.sCurve.slide4.reflection.high.q2')}</p>
            <p>{t('activity.sCurve.slide4.reflection.high.q3')}</p>
            <p>{t('activity.sCurve.slide4.reflection.high.q4')}</p>
            <p>{t('activity.sCurve.slide4.reflection.high.q5')}</p>
          </>
        ),
      };
    }

    if (value === 100) {
      return {
        prompts: (
          <>
            <p>{t('activity.sCurve.slide4.reflection.mastery.q1')}</p>
            <p>{t('activity.sCurve.slide4.reflection.mastery.q2')}</p>
          </>
        ),
      };
    }
  };

  const { prompts } = getReflectionPrompts();

  return (
    <div
      className="mt-12 px-12 xl:px-0"
      ref={pageRef}
      style={{ backgroundColor: 'white' }}
    >
      <div className="mx-4 flex flex-row justify-evenly">
        <div className="text-xl w-1/3">
          <b>
            <p>Goal:</p>
          </b>
          <p>{sCurve?.goal}</p>
        </div>
        <div>
          <SCurveSlider
            value={value}
            setValue={setValue}
            className={styles.slider}
            isInteractive={false}
          />
        </div>
      </div>
      <Alert
        severity="info"
        variant="standard"
        icon={<MdLightbulbOutline />}
        className={styles.alert}
      >
        <h2>
          <b>{t('activity.sCurve.slide4.header2')}</b>
        </h2>
        <h4>{t('activity.sCurve.slide4.description1')}</h4>
      </Alert>
      <Reflections
        prompt={
          <>
            <h2>{t('activity.sCurve.slide4.reflection.title')}</h2>
            <p>{prompts}</p>
          </>
        }
        id="scurve-reflection"
        reflection={reflection}
        onChange={(event) => setReflection(event.target.value)}
      />
      <div className="col flex justify-between">
        <NavigationButton onClick={handleBack} />
        <div className={styles.submitContainer}>
          <Button onClick={handleClick}>Submit</Button>
        </div>
      </div>
    </div>
  );
};

export default SCurveReflection;

import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import useSCurve from '../../../../hooks/useSCurve';
import NavigationButton from '../../../shared/NavigationButton';
import Reflections from '../../Reflections';
import SCurveSlider from '../SCurveSlider';

import styles from './SCurvePlot.module.css';

const SCurvePlot = ({
  handleNext,
  handleBack,
}: {
  handleNext: () => void;
  handleBack: () => void;
}) => {
  const { t } = useTranslation();
  const { updateSCurveGoal, updateSCurveStage, sCurve } = useSCurve();

  const [loading, setLoading] = useState(true);
  const [goal, setGoal] = useState(sCurve?.goal ?? '');
  const [level, setLevel] = useState(sCurve?.stage ?? 0);

  useEffect(() => {
    if (loading === true) {
      (async () => {
        if (!sCurve) {
          navigate('app/journal/activity/sCurve');
          toast.error(t('activity.sCurve.toasts.doesNotExist'));
          return null;
        }

        setLoading(false);
      })();
    }
  }, [loading, sCurve]);

  useEffect(() => {
    if (loading === true) {
      return;
    }
    updateSCurveGoal(goal);
    updateSCurveStage(level);
  }, [goal, level, setLevel, setGoal]);

  return (
    <>
      <Grid container spacing={3} className="flex justify-center px-12 xl:px-0">
        <Grid item xs={8} className="h-1/2 text-2xl text-center">
          <Reflections
            id="scurve-goal"
            reflection={goal}
            onChange={(e) => setGoal(e.target.value)}
            prompt={
              <>
                <h4>{t('activity.sCurve.slide3.description')}</h4>
              </>
            }
          />
        </Grid>

        <Grid item xs={12} className="flex justify-center px-12 xl:px-0">
          <SCurveSlider
            value={level}
            setValue={setLevel}
            className={styles.slider}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="flex justify-between">
            <NavigationButton onClick={handleBack} />
            <NavigationButton onClick={handleNext} isNext alignRight />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SCurvePlot;

import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Container from '@material-ui/core/Container';

import LoadingScreen from '../../../../components/router/LoadingScreen';
import TopNavbar from '../../../../components/shared/TopNavbar';

import SCurveIntro from '../../../../components/journal/sCurve/slides/SCurveIntro';
import SCurveDescription from '../../../../components/journal/sCurve/slides/SCurveDescription';
import SCurvePlot from '../../../../components/journal/sCurve/slides/SCurvePlot';
import SCurveReflection from '../../../../components/journal/sCurve/slides/SCurveReflection';

import useSCurve from '../../../../hooks/useSCurve';
import uuid from '../../../../utils/uuid';

import styles from './piechart.module.css';

const SCurve = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const { loadSCurve, createSCurve, sCurve } = useSCurve();
  const [loading, setLoading] = useState(true);
  const [slide, setSlide] = useState(0);
  const [activityId, setActivityId] = useState(id);

  useEffect(() => {
    if (activityId == null) {
      const newId = uuid();
      createSCurve(newId);
      setActivityId(newId);
    } else {
      loadSCurve(activityId);
    }
    setLoading(false);
  }, [activityId]);

  useEffect(() => {
    if (slide !== 0) return;
    if (loading) return;
    if (sCurve == null && id != null) {
      navigate(-1);
      toast.error(t('activity.sCurve.toasts.doesNotExist'));
    }
    setSlide(1);
  }, [sCurve, loading, setSlide]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>S-Curve of Learning</title>
        <meta property="og:type" content="website" />
        <meta property="og:description" content="S-Curve of Learning" />
        <meta
          property="og:image"
          content="https://careersocius-portal.web.app/static/4525c5a596897c4eda7fffd1cb4c431c/9e6d9/piechart.png"
        />
      </Helmet>
      <TopNavbar />
      <Container className={styles.contentContainer}>
        {slide === 1 && <SCurveIntro setSlide={setSlide} />}
        {slide === 2 && (
          <SCurveDescription
            handleNext={() => setSlide(3)}
            handleBack={() => setSlide(1)}
          />
        )}
        {slide === 3 && (
          <SCurvePlot
            handleNext={() => setSlide(4)}
            handleBack={() => setSlide(2)}
          />
        )}
        {slide === 4 && <SCurveReflection setSlide={setSlide} />}
      </Container>
    </>
  );
};

export default SCurve;

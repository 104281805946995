export type Point = {
  x: number;
  y: number;
};
export const drawLine = (
  context: CanvasRenderingContext2D,
  startingPoint: Point,
  endingPoint: Point,
  strokeStyle: string,
  isDotted?: boolean,
) => {
  context.strokeStyle = strokeStyle;
  if (isDotted) {
    context.setLineDash([5, 10]);
  } else {
    context.setLineDash([0]);
  }
  context.lineWidth = 2;
  context.beginPath();
  context.moveTo(startingPoint.x, startingPoint.y);
  context.lineTo(endingPoint.x, endingPoint.y);
  context.stroke();
};

export const writeText = (
  context: CanvasRenderingContext2D,
  text: string,
  font: string,
  fillStyle: string,
  position: Point,
  offset: number,
) => {
  context.font = font;
  context.fillStyle = fillStyle;
  context.fillText(text, position.x - offset, position.y);
};

export const drawTriangle = (
  context: CanvasRenderingContext2D,
  p1: Point,
  p2: Point,
  p3: Point,
  fillStyle: string,
) => {
  context.fillStyle = fillStyle;
  context.beginPath();
  context.moveTo(p1.x, p1.y);
  context.lineTo(p2.x, p2.y);
  context.lineTo(p3.x, p3.y);
  context.lineTo(p1.x, p1.y);
  context.fill();
};

export const drawDoubleEndedArrow = (
  context: CanvasRenderingContext2D,
  height: number,
  p1: Point,
  p2: Point,
  fillStyle: string,
) => {
  const arrowDepth = 10;
  drawTriangle(
    context,
    p1,
    { x: p1.x + arrowDepth, y: p1.y + height / 2 },
    { x: p1.x + arrowDepth, y: p1.y - height / 2 },
    fillStyle,
  );
  drawTriangle(
    context,
    p2,
    { x: p2.x - arrowDepth, y: p2.y + height / 2 },
    { x: p2.x - arrowDepth, y: p2.y - height / 2 },
    fillStyle,
  );
  drawLine(context, p1, p2, fillStyle);
};
